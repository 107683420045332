import React from 'react';
import cn from 'classnames';

import styles from './upperBlock.module.scss';
import three from '../images/three.svg';

function UpperBlock({ text, splitSubTitle }) {
  const { title, subTitle, marked } = text;
  return (
    <div className={styles.wrapper}>
      {splitSubTitle ? (
        <div className={styles.imgNumber} id={title.anchor}>
          <img src={three} alt={title.anchor} className={styles.numImg} />
          <p
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title.name }}
          />
        </div>
      ) : (
        <p className={styles.title}>{title.name}</p>
      )}
      {splitSubTitle ? (
        <div className={cn(styles.splitSubTitle, styles.subTitle)}>
          <p dangerouslySetInnerHTML={{ __html: subTitle.left }} />
          <p dangerouslySetInnerHTML={{ __html: subTitle.right }} />
        </div>
      ) : (
        <p
          className={styles.subTitle}
          dangerouslySetInnerHTML={{ __html: subTitle.left }}
        />
      )}
      <p className={styles.marked}>{marked}</p>
    </div>
  );
}

export default UpperBlock;
