import React from 'react';

import styles from './author.module.scss';

function Author({ author }) {
  const { img, fullName, description } = author;
  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <img className={styles.img} alt="author" src={img} />
        <p className={styles.fullName}>{fullName}</p>
      </div>
      <p className={styles.description}>{description}</p>
    </div>
  );
}

export default Author;
