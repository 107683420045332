import React from 'react';
import cn from 'classnames';

import styles from './blockHeader.module.scss';

function BlockHeader({
  type = 'dark',
  firstLine,
  secondLine,
  anchor = null,
  customClassName,
  children,
}) {
  return (
    <div className={styles.headerWr}>
      <div
        id={anchor}
        className={cn(styles.headerContainer, {
          [styles.headerContainerLight]: type !== 'dark',
          [customClassName]: customClassName,
        })}
      >
        {firstLine && (
          <span className={styles.format}>
            &lt;
            {firstLine}
            &gt;
          </span>
        )}
        <h3 className={styles.sectionHeader}>{secondLine}</h3>
      </div>
      {children}
    </div>
  );
}

export default BlockHeader;
