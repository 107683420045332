import React from 'react';

import OneStereotype from './OneStereotype/OneStereotype';
import styles from './stereotypes.module.scss';

function Stereotypes({ stereotypes }) {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>&lt;Немного о стереотипах&gt;</p>
      {stereotypes.map((stereotype, index) => (
        <OneStereotype stereotype={stereotype} key={index} />
      ))}
    </div>
  );
}

export default Stereotypes;
