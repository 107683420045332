import React from 'react';
import cn from 'classnames';

import styles from './singleBlock.module.scss';
import two from '../images/two.svg';

function SingleBlock({ block }) {
  const { header, title, subTitle, right } = block;
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        {header && (
          <div className={styles.imgNumber} id={header.anchor}>
            <img src={two} alt={header.anchor} className={styles.numImg} />
            <p className={styles.subTitle}>{header.name}</p>
          </div>
        )}
        {title && <p className={styles.title}>{title}</p>}
        {subTitle?.text1 && (
          <p
            className={cn(styles.text, styles.marginBottom)}
            dangerouslySetInnerHTML={{ __html: subTitle.text1 }}
          />
        )}
        {subTitle.marked && (
          <p className={styles.markedTitle}>{subTitle.marked}</p>
        )}
        {subTitle.text2 && <p className={styles.text}>{subTitle.text2}</p>}
      </div>
      <div className={styles.right}>
        <p className={styles.rightTitle}>{right.title}</p>
        <p
          className={styles.rightSubTitle}
          dangerouslySetInnerHTML={{ __html: right.subTitle }}
        />
        <img src={right.img} alt={right.title} className={styles.rightImg} />
        {right?.imgSecond && (
          <img
            src={right.imgSecond}
            alt={right.subTitle}
            className={cn(styles.rightImg, styles.rightImgSecond)}
          />
        )}
      </div>
    </div>
  );
}

export default SingleBlock;
