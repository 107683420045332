import React from 'react';
import styles from './jobs.module.scss';
import UpperBlock from './UpperBlock/UpperBlock';

function Jobs({ jobs }) {
  const { stats, right } = jobs;
  return (
    <div className={styles.wrapper}>
      <div className={styles.bg}>
        <UpperBlock text={jobs} splitSubTitle />
        <div className={styles.statImgContent}>
          <div className={styles.stats}>
            {stats.map(({ number, info }, index) => (
              <div className={styles.stat} key={index}>
                <p className={styles.number}>{number}</p>
                <p className={styles.info}>{info}</p>
              </div>
            ))}
          </div>
          <div className={styles.right}>
            <p className={styles.rightTitle}>{right.title}</p>
            <p className={styles.rightSubTitle}>{right.subTitle}</p>
            <img
              src={right.img}
              alt={right.title}
              className={styles.rightImg}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jobs;
