import React from 'react';

import UpperBlock from '../Jobs/UpperBlock/UpperBlock';
import styles from './money.module.scss';

function Money({ json }) {
  const { stats, right } = json;

  return (
    <div className={styles.wrapper}>
      <div className={styles.bg}>
        <UpperBlock text={json} />
        <div className={styles.statImgContent}>
          <div className={styles.stats}>
            {stats.map(({ number, info }, index) => (
              <div className={styles.stat} key={index}>
                <p className={styles.number}>{number}</p>
                <p className={styles.info}>{info}</p>
              </div>
            ))}
          </div>
          <div className={styles.right}>
            <p className={styles.rightTitle}>{right.title}</p>
            <p className={styles.rightSubTitle}>{right.subTitle}</p>
            <div className={styles.rightImg} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Money;
