import React from 'react';
import scrollToElement from 'scroll-to-element';

import styles from './stepsNavigation.module.scss';
import one from '../images/one.svg';
import two from '../images/two.svg';
import three from '../images/three.svg';
import four from '../images/four.svg';

const enumNumber = {
  0: one,
  1: two,
  2: three,
  3: four,
};

function StepsNavigation({ stepsNavigation, online }) {
  return (
    <div className={styles.stepsNavContainer}>
      {stepsNavigation.map((step, i) => {
        const { title, anchor } = step;
        return (
          <div
            onClick={() => {
              scrollToElement(anchor.replace(/\/job-report2021\//, ''), {
                offset: !online && -100,
                duration: 1000,
              });
            }}
            className={styles.step}
            key={i}
          >
            <img
              src={enumNumber[i]}
              alt={enumNumber[i]}
              className={styles.numImg}
            />
            <p
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        );
      })}
    </div>
  );
}

export default StepsNavigation;
