import React from 'react';
import { navigate } from 'gatsby';

import styles from './resume.module.scss';
import goldFour from './images/goldFour.svg';

function Resume({ json, url }) {
  const { title, left, right, button } = json;

  return (
    <div className={styles.wrapper}>
      <div className={styles.imgNumber} id={title.anchor}>
        <img src={goldFour} alt={title.anchor} className={styles.numImg} />
        <p className={styles.subTitle}>{title.name}</p>
      </div>
      <div className={styles.text}>
        <div className={styles.column}>
          <p className={styles.columnTitle}>{left.title}</p>
          <p
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: left.main }}
          />
        </div>
        <br />
        <br />
        <div className={styles.column}>
          <p className={styles.columnTitle}>{right.title}</p>
          <p
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: right.main }}
          />
        </div>
      </div>
      <button
        className={styles.button}
        onClick={() => {
          navigate(url || button.link);
        }}
      >
        {button.name}
      </button>
    </div>
  );
}

export default Resume;
