import React from 'react';
import First from './First/First';
import styles from './mainBlock.module.scss';
import Second from './Second/Second';
import StepsNavigation from './StepsNavigation/StepsNavigation';

function MainBlock({ json, online }) {
  const { first, stepsNavigation, second } = json;

  return (
    <div className={styles.wrapper}>
      <First first={first} />
      <div className={styles.slashWrapper}>
        <div className={styles.slash} />
      </div>
      <StepsNavigation stepsNavigation={stepsNavigation} online={online} />
      <Second second={second} />
    </div>
  );
}

export default MainBlock;
