import React from 'react';

import { useOnlineJobReportQuery } from '../app/queries/useOnlineJobReportQuery';
import Layout from '../shared/Layout/Layout';
import MainBlock from '../shared/MainBlock/MainBlock';
import BlocksImg from '../shared/hse/BlocksImg/BlocksImg';
import Jobs from '../shared/hse/Jobs/Jobs';
import Works from '../shared/hse/Works/Works';
import Money from '../shared/hse/Money/Money';
import Resume from '../shared/hse/Resume/Resume';
import Authors from '../shared/hse/Authors/Authors';
import HeadMaker from '../HeadMaker';
import Stereotypes from '../shared/hse/Stereotypes/Stereotypes';

export function Head(props) {
  const jobReportJson = useOnlineJobReportQuery();
  return (
    <HeadMaker
      {...props}
      seo={{ title: jobReportJson.title, meta: jobReportJson.meta }}
    />
  );
}

function JobReport() {
  const jobReportJson = useOnlineJobReportQuery();

  return (
    <Layout>
      <MainBlock json={jobReportJson.mainBlock} online />
      <BlocksImg blocks={jobReportJson.blocks} />
      <Stereotypes stereotypes={jobReportJson.stereotypes} />
      <Jobs jobs={jobReportJson.jobs} />
      <Works json={jobReportJson.works} />
      <Money json={jobReportJson.money} />
      <Resume json={jobReportJson.resume} url="/#program" />
      <Authors json={jobReportJson.authors} />
    </Layout>
  );
}

export default JobReport;
