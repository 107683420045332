import { useStaticQuery, graphql } from 'gatsby';

export const useOnlineJobReportQuery = () => {
  const { jobReportJson } = useStaticQuery(graphql`
    query JobReportOnlineNewQuery {
      jobReportJson {
        title

        meta {
          property
          content
          name
        }

        mainBlock {
          first {
            label
            preTitle
            title
            subTitle {
              text1
              marked {
                bulletsTitle
                bullets
              }
              text2
            }
            img
            links {
              name
              link
            }
          }
          stepsNavigation {
            title
            anchor
          }
          second {
            subTitle
            markedTitle
            text1
            bullets
            text2
            img
            stats {
              number
              info
            }
          }
        }

        blocks {
          header {
            anchor
            name
          }
          title
          subTitle {
            text1
            marked
            text2
          }
          right {
            title
            subTitle
            img
            imgSecond
          }
        }

        stereotypes {
          title
          subTitle
          marked {
            text
            img
          }
          stats {
            number
            info
          }
        }

        jobs {
          title {
            name
            anchor
          }
          subTitle {
            left
            right
          }
          marked
          stats {
            number
            info
          }
          right {
            title
            subTitle
            img
          }
        }

        money {
          title {
            name
          }
          subTitle {
            left
          }
          marked
          stats {
            number
            info
          }
          right {
            title
            subTitle
          }
        }

        works {
          title {
            name
          }
          subTitle {
            left
          }
          marked
          stats {
            number
            info
            title
            subTitle
            img
          }
          text
        }

        resume {
          title {
            name
            anchor
          }
          left {
            title
            main
          }
          right {
            title
            main
          }
          button {
            name
            link
          }
        }

        authors {
          img
          fullName
          description
        }
      }
    }
  `);

  return jobReportJson;
};
