import React from 'react';
import cn from 'classnames';

import styles from './oneStereotype.module.scss';

function OneStereotype({ stereotype }) {
  const { title, subTitle, marked, stats } = stereotype;
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p className={styles.text}>{subTitle}</p>
      </div>
      <div className={styles.marked}>
        {marked?.text && (
          <p className={cn(styles.markedText, styles.text)}>{marked.text}</p>
        )}
        {marked?.img && (
          <img src={marked.img} alt="chart" className={styles.markedImg} />
        )}
      </div>
      <div className={styles.stats}>
        {stats?.number && <p className={styles.statsNumber}>{stats.number}</p>}
        <p className={styles.text}>{stats.info}</p>
      </div>
    </div>
  );
}

export default OneStereotype;
