import React from 'react';
import styles from './blocksImg.module.scss';
import SingleBlock from './SingleBlock/SingleBlock';

function BlocksImg({ blocks }) {
  return (
    <div className={styles.wrapper}>
      {blocks.map((block, i) => (
        <SingleBlock block={block} index={i} key={i} />
      ))}
    </div>
  );
}

export default BlocksImg;
