import React from 'react';
import styles from './First.module.scss';

function First({ first }) {
  const { label, preTitle, title, subTitle, img, links } = first;

  return (
    <div className={styles.firstWrapper}>
      <div className={styles.wrapper}>
        <span className={styles.label}>{label}</span>
        <p className={styles.preTitle}>{preTitle}</p>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.subTitle}>
          <p>{subTitle.text1}</p>
          <div className={styles.marked}>
            <p className={styles.text}>{subTitle.marked.bulletsTitle}</p>
            {subTitle.marked.bullets.map((bullet, i) => (
              <p className={styles.bullet} key={i}>
                {bullet}
              </p>
            ))}
          </div>
          <p>{subTitle.text2}</p>
        </div>
      </div>
      <div className={styles.imgWrapper}>
        <img src={img} className={styles.img} alt="hse" />
        <div className={styles.links}>
          {links.map(({ name, link }, index) => (
            <a
              key={index}
              className={styles.link}
              href={link}
              rel="noopener noreferrer"
              target="_blank"
            >
              {name}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default First;
