import React from 'react';
import UpperBlock from '../Jobs/UpperBlock/UpperBlock';
import styles from './works.module.scss';

function Works({ json }) {
  const { stats, text } = json;

  return (
    <div className={styles.wrapper}>
      <div className={styles.bg}>
        <UpperBlock text={json} />
        <div className={styles.statImgContent}>
          <div className={styles.stats}>
            <div className={styles.stat}>
              <p className={styles.number}>{stats[0].number}</p>
              <p className={styles.info}>{stats[0].info}</p>
            </div>
            <div className={styles.stat}>
              <p className={styles.number}>{stats[1].number}</p>
              <p className={styles.info}>{stats[1].info}</p>
            </div>
          </div>

          <div className={styles.stats}>
            <div className={styles.right}>
              <p className={styles.rightTitle}>{stats[1].title}</p>
              <p className={styles.rightSubTitle}>{stats[1].subTitle}</p>
              <img
                src={stats[1].img}
                alt="Формат работы"
                className={styles.rightImg}
              />
            </div>
            <div className={styles.right}>
              <p className={styles.rightTitle}>{stats[0].title}</p>
              <p className={styles.rightSubTitle}>{stats[0].subTitle}</p>
              <img
                src={stats[0].img}
                alt="График работы"
                className={styles.rightImg}
              />
            </div>
          </div>

          <p className={styles.text}>{text}</p>
        </div>
      </div>
    </div>
  );
}

export default Works;
