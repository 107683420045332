import React from 'react';

import Author from './Author/Author';
import styles from './authors.module.scss';
import BlockHeader from '../BlockHeader/BlockHeader';

function Authors({ json }) {
  return (
    <div className={styles.wrapper}>
      <BlockHeader secondLine="Авторы" anchor="authors" />
      <div className={styles.wrapperAuthors}>
        {json.map((author, index) => (
          <Author key={index} author={author} />
        ))}
      </div>
    </div>
  );
}

export default Authors;
