import React from 'react';
import styles from './Second.module.scss';
import one from '../images/one.svg';

function Second({ second }) {
  const { subTitle, markedTitle, text1, bullets, text2, img, stats } = second;

  return (
    <div className={styles.firstWrapper} id="main_question">
      <div className={styles.wrapper}>
        <div className={styles.imgNumber}>
          <img src={one} alt="main question" className={styles.numImg} />
          <p className={styles.subTitle}>{subTitle}</p>
        </div>
        <p className={styles.markedTitle}>{markedTitle}</p>
        <p
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: text1 }}
        />
        {bullets.map((bullet, i) => (
          <div className={styles.bullet} key={i}>
            {bullet}
          </div>
        ))}
        <p className={styles.text}>{text2}</p>
      </div>
      <div className={styles.imgWrapper}>
        <img src={img} className={styles.img} alt="developers" />
        <div className={styles.stats}>
          <p className={styles.number}>{stats.number}</p>
          <p className={styles.info}>{stats.info}</p>
        </div>
      </div>
    </div>
  );
}

export default Second;
